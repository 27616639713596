import MainSideBar from "../../../Components/SideBar";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetBranchListQuery, usePostBranchComparisonMutation } from "../../../Services/ApiServices";
import { Button, FormControlLabel, Checkbox, FormGroup, Box, Grid, Typography } from "@mui/material";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import { useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";

export default function CompareBranches() {
  const [branchList, setBranchList] = useState({});
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [err, setErr] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  
  const { data, error } = useGetBranchListQuery();
  const [postBranchComparison] = usePostBranchComparisonMutation();

  useEffect(() => {
    if (data?.data?.branches) {
      setBranchList(data.data.branches);
    } else if (error) {
      setErr({ error: true, message: "Failed to load branches" });
    }
  }, [data, error]);

  const handleBranchToggle = (branchId) => {
    setSelectedBranches(prev => 
      prev.includes(branchId) 
        ? prev.filter(id => id !== branchId) 
        : [...prev, branchId]
    );
  };
  const getToken = () => sessionStorage.getItem("token") || "";

  const handleCompare = async () => {
    if (selectedBranches.length > 1) {
      setLoading(true);
      try {
        const url = `http://161.35.229.180/api/comparisons/branches`;
        const headers = {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: getToken(),
        };
        const body = {
          branchIds: selectedBranches,
        };
  
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify(body),
        });
  
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = "";
  
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
  
          // Decode the streamed data and append to the buffer
          buffer += decoder.decode(value, { stream: true });
  
          // Process complete JSON objects in the buffer
          let boundary;
          while ((boundary = buffer.indexOf("\n")) !== -1) {
            // Extract the first complete JSON object
            const jsonString = buffer.slice(0, boundary).trim();
            buffer = buffer.slice(boundary + 1); // Remove the processed part from the buffer
  
            if (jsonString) {
              try {
                // Remove the "data: " prefix if it exists
                const cleanedJsonString = jsonString.startsWith("data: ")
                  ? jsonString.slice(6) // Remove "data: "
                  : jsonString;
  
                const data = JSON.parse(cleanedJsonString);
  
  
                if (data.progress !== undefined) {
                  setProgress(data.progress); // Update progress
                } else if (data.success) {
                  // Handle the final data (e.g., set it to state or process it further)
          navigate("/ViewBranchComparison", { state: { comparisonData: data.data } });
  
                }
                else{
                  
                  setErr({error:true,message:data.data.message})
                }
              } catch (error) {
                console.error("Error parsing JSON:", error);
                console.error("Problematic JSON string:", jsonString);
              }
            }
          }
        }
  
        // Handle any remaining data in the buffer
        if (buffer.trim()) {
          try {
            // Remove the "data: " prefix if it exists
            const cleanedJsonString = buffer.startsWith("data: ")
              ? buffer.slice(6) // Remove "data: "
              : buffer;
  
            const data = JSON.parse(cleanedJsonString);
          navigate("/ViewBranchComparison", { state: { comparisonData: data.data } });
  
  
            // Handle the final data
          } catch (error) {
            console.error("Error parsing final JSON:", error);
            console.error("Problematic JSON string:", buffer);
          }
        }
  
        setLoading(false);
      } catch (error) {
        if (
          error.response?.status === 400 ||
          error.response?.status === 440
        ) {
          sessionStorage.removeItem("token"); // Clear token
          navigate("/login"); // Redirect to login page
        } else {
          setErr({ error: true, message: error.message });
        }
     
      }
    } else {
      setErr({ error: true, message: "Please select at least two branches" });
    }
  };
    

  return (
    <MainSideBar>
      <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "auto" }}>
        <Typography variant="h4" gutterBottom>
          Compare Branches
        </Typography>

        {loading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress 
              variant="determinate" 
              value={progress}
              sx={{
                height: 10,
                borderRadius: 5,
                backgroundColor: "#e0e0e0",
                "& .MuiLinearProgress-bar": { backgroundColor: "#4caf50" }
              }}
            />
            <Typography variant="body2" align="center" mt={1}>
              Processing: {progress.toFixed(2)}%
            </Typography>
          </Box>
        ) : err.error ? (
          <Typography color="error">{err.message}</Typography>
        ) : (
          <Box sx={{ mb: 4, backgroundColor: "white", p: 3, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
              Select Branches to Compare
            </Typography>

            {Object.entries(branchList).map(([region, branches]) => (
              <Box key={region} sx={{ mb: 4 }}>
                <Typography 
                  variant="subtitle1"
                  sx={{
                    fontWeight: 700,
                    p: 1,
                    backgroundColor: "#f8f9fa",
                    borderRadius: 1,
                    mb: 2
                  }}
                >
                  {region}
                </Typography>
                
                <Grid container spacing={3}>
                  {branches.map(branch => (
                    <Grid item xs={12} sm={6} md={4} key={branch.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedBranches.includes(branch.id)}
                            onChange={() => handleBranchToggle(branch.id)}
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="body1" fontWeight={500}>
                            {branch.name}
                          </Typography>
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ))}

            <Button
              variant="contained"
              color="primary"
              onClick={handleCompare}
              disabled={selectedBranches.length < 2 || loading}
              sx={{ mt: 3, px: 4, py: 1.5 }}
            >
              {loading ? "Comparing..." : "Compare Selected Branches"}
            </Button>
          </Box>
        )}
      </Box>
    </MainSideBar>
  );
}