import { useDispatch, useSelector } from "react-redux";
import MainSideBar from "../../Components/SideBar";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import {apiSlice, useGetAdBranchesQuery} from "../../Services/ApiServices"
import { addPlace } from "../../App/features/placeSlice";
import FetchLoading from "../../Components/Messages/FetchLoading"
import NoData from "../../Components/NoData"
import { Button, Grid } from "@mui/material";
import HotelCard from "../../Components/HotelCard"

export default function AdminBranches () {
    const dispatch = useDispatch()
    const places = useSelector(state=> state.place)
    const regionId = useSelector(state=> state.AdId.id)
    const name = useSelector(state=> state.user.firstName)
    const theme = useTheme()
    const [err, setErr] = useState({error: false, message: ""})
        const [loading, setLoading] = useState(true)
        const { data, refetch, isLoading, error } = useGetAdBranchesQuery(regionId)
        const updater = async () =>{
        dispatch(apiSlice.util.resetApiState())
        const res = await refetch({ forceRefetch: true }).then((response)=>{
            setLoading((prev) =>{ return false})
        if(response.isError) {
            setErr((prev) => { return {...prev, error: true, message: response}})
        }
        if(response.isSuccess) {
            dispatch(addPlace(response.data.data.places))
        }
        })
    }
    useEffect(()=>{
        updater()
    },[])
    return (
        <MainSideBar>
            {
            loading ? <FetchLoading/> :
            <>
            {(places.length > 0) ? <div>
            <Button variant='outlined' onClick={() =>{ window.history.go(-1); return false;}}>Go Back</Button>
                <Grid container spacing={2} style={{ marginTop:"1%" }}>   
            {
                places.map((item) => {
                    return <HotelCard
                theme={theme}
                key={item.id} 
                getDetails={()=>{console.log(item.id)}} 
                title={item.displayName} 
                description={item.formattedAddress} 
                image= {item.imageUrl} 
                id={item.id} 
                regionId = {item.regionId} />
                })
                }
                </Grid>  
                </div>
                :
                <NoData message={"No Branches Yet."} />   
            }
            </>
            }
        </MainSideBar>
    )
}