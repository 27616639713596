import { MenuItem, Select } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useMemo,useState } from "react";


export default function Comparison({categories,barChartData,comparisonData}){

    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
      const [selectedInterval, setSelectedInterval] = useState("annually");

      const branches = useMemo(() => {
        if (!barChartData || !selectedCategory) return []; // Return an empty array if no data or category is selected
        return Object.keys(barChartData[selectedCategory]);
      }, [barChartData, selectedCategory]);

// Prepare the data for the chart based on the selected interval and branch
const prepareChartData = (branch) => {
    if (!barChartData || !selectedCategory || !branch) return { options: {}, series: [] };

    const sentiments = ["Worst", "Bad", "Neutral", "Good", "Best"]; // Removed "None"

    // Filter and group data based on the selected interval
    const filteredData = barChartData[selectedCategory][branch];
    const groupedData = {};

    filteredData.forEach((item) => {
      const date = new Date(item.published_date);
      let key;

      if (selectedInterval === "monthly") {
        key = `${date.getFullYear()}-${date.toLocaleString("default", { month: "long" })}`;
      } else if (selectedInterval === "quarterly") {
        const quarter = Math.floor((date.getMonth() + 3) / 3);
        key = `${date.getFullYear()}-Q${quarter}`;
      } else if (selectedInterval === "semi-annually") {
        const half = date.getMonth() < 6 ? "H1" : "H2";
        key = `${date.getFullYear()}-${half}`;
      } else if (selectedInterval === "annually") {
        key = date.getFullYear().toString();
      }

      if (!groupedData[key]) {
        // Initialize all sentiment keys to 0
        groupedData[key] = { Worst: 0, Bad: 0, Neutral: 0, Good: 0, Best: 0 };
      }
      if (item.value !== "None" && groupedData[key][item.value] !== undefined) {
        groupedData[key][item.value]++;
      }
    });

    // Sort categories chronologically
    const sortCategories = (categories) => {
      if (selectedInterval === "monthly") {
        const monthOrder = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        return categories.sort((a, b) => {
          const aParts = a.split("-");
          const bParts = b.split("-");
          const aYear = parseInt(aParts[0]);
          const bYear = parseInt(bParts[0]);
          const aMonth = aParts[1];
          const bMonth = bParts[1];
          return aYear !== bYear ? aYear - bYear : monthOrder.indexOf(aMonth) - monthOrder.indexOf(bMonth);
        });
      }
      return categories.sort();
    };

    const sortedCategories = sortCategories(Object.keys(groupedData));

    // Define colors for each sentiment (red to green gradient)
    const sentimentColors = ["#FF0000", "#FF5733", "#FFC300", "#7CFC00", "#008000"];

    // Prepare series data for the chart
    const series = sentiments.map((sentiment, index) => ({
      name: sentiment,
      data: sortedCategories.map((category) => {
        const total = Object.values(groupedData[category]).reduce((a, b) => a + b, 0);
        const count = groupedData[category][sentiment] || 0;
        return total === 0 ? 0 : (count / total) * 100; // Calculate percentage
      }),
      color: sentimentColors[index], // Assign color based on sentiment
    }));

    return {
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false, // Separate columns for each sentiment
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            dataLabels: {
              enabled: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: sortedCategories,
          title: {
            text: selectedInterval === "annually" ? "Year" : selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1),
          },
        },
        yaxis: {
          title: {
            text: "Percentage",
          },
          labels: {
            formatter: (value) => `${value.toFixed(2)}%`,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => `${value.toFixed(2)}%`,
          },
        },
        legend: {
          position: "top",
        },
      },
      series,
    };
  };

  const formatComparisonData = (data) => {
    if (!data) return null;

    // Remove ** and ## from the text
    const cleanedData = data.replace(/\*\*/g, "").replace(/##/g, "");

    // Split the data into sections based on headings
    const sections = cleanedData.split("\n\n");

    return sections.map((section, index) => {
      const lines = section.split("\n");
      const heading = lines[0].trim();
      const content = lines.slice(1).join("\n");

      return (
        <div key={index} style={{ marginBottom: "20px" }}>
          <h4 style={{ marginBottom: "10px" }}>{heading}</h4>
          <p style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{content}</p>
        </div>
      );
    });
  };
      
    return <div
    style={{
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      position: "relative",
    }}
  >
    <div style={{ marginBottom: "20px" }}>
      <div
        style={{
          width: "200px",
          display: "inline-block",
          marginRight: "20px",
        }}
      >
        <label>Select Category: </label>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div
        style={{
          width: "200px",
          display: "inline-block",
          marginRight: "20px",
        }}
      >
        <label>Select Interval: </label>
        <Select
          value={selectedInterval}
          onChange={(e) => setSelectedInterval(e.target.value)}
        >
          <MenuItem value="annually">Annually</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="quarterly">Quarterly</MenuItem>
          <MenuItem value="semi-annually">Semi-Annually</MenuItem>
        </Select>
      </div>
    </div>


    {/* Render bar charts for each branch */}
    {branches.map((branch) => (
      <div key={branch} style={{ marginBottom: "40px" }}>
        <h3>{branch}</h3>
        <ReactApexChart
          options={prepareChartData(branch).options}
          series={prepareChartData(branch).series}
          type="bar"
          height={350}
        />
      </div>
    ))}

    {/* Display comparison data at the bottom */}
    <div style={{ marginTop: "40px" }}>
      <h3>Comparison Data for {selectedCategory}</h3>
      {formatComparisonData(
        comparisonData[selectedCategory]
      )}
    </div>
  </div>
}