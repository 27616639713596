import { createSlice } from "@reduxjs/toolkit";

const ReportSlice = createSlice({
    name:"report",
    initialState: {},
    reducers: {
        addId:(state, action) => { return {...state, id: action.payload}},
        addReport:(state, action) => { return { id: state.id, ...action.payload}},
        removeReport:(state) => { return {}}
    }
})


export default ReportSlice.reducer
export const {addReport, removeReport, addId} = ReportSlice.actions;