import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Rating,
  Typography,
} from "@mui/material";
import ReviewsTable from "./ReviewTable";
import extractJSONFromString from "../../Utils/JSONExtractor";

export default function AiTable({ nexusState }) {
  return (
    <Box>
      <Paper sx={{ padding: "2%" }}>
        <Typography variant="h5" color="primary" sx={{ marginBottom: 3 }}>
          High attention
        </Typography>
        <Divider />
        <ReviewsTable
          reviewsArray={
            nexusState.classification &&
            typeof nexusState.classification === "string"
              ? extractJSONFromString(nexusState.classification)
              : null
          }
        />
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" color="primary" sx={{ marginBottom: 1 }}>
          Summary
        </Typography>
        <Divider />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {nexusState.summary}
        </Typography>
      </Paper>
    </Box>
  );
}
