// features/reportDataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    graphData: null,
};

const reportDataSlice = createSlice({
  name: "graphData", // Slice name
  initialState,
  reducers: {
    setGraphData: (state, action) => {
      state.graphData = action.payload;
    },
    resetReportData: (state) => {
      state.graphData = null;
    },
  },
});
export const {  setGraphData,  resetReportData } = reportDataSlice.actions;
export default reportDataSlice.reducer;