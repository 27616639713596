import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import MainSideBar from "../../../Components/SideBar";
import { 
  MenuItem, 
  Select, 
  Snackbar, 
  Alert, 
  Grid, 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Box
} from "@mui/material";
import Comparison from "../../../Components/Comparison";
import { useAddReportMutation } from "../../../Services/ApiServices";

const SentimentBarChart = () => {
  const location = useLocation();
  const comparisonData = location.state?.comparisonData;
  const [addReport] = useAddReportMutation();
  const [success, setSuccess] = useState(false);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [reportTitle, setReportTitle] = useState("");
  
  const categories = useMemo(() => {
    if (!comparisonData) return [];
    return Object.keys(comparisonData.bar_chart_data);
  }, [comparisonData]);

  const handleCloseSnackbar = () => setSuccess(false);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const generateReport = async (title) => {
    const defaultTitle = `Comparison - ${getCurrentDate()}`;
    
    const reportDataPayload = {
      title: title || defaultTitle,
      type: "Comparison",
      report: {
        categories: categories,
        barChartData: comparisonData.bar_chart_data,
        comparisonData: comparisonData.comparison_data,
      },
    };

    try {
      const response = await addReport(reportDataPayload).unwrap();
      setSuccess(true);
    } catch (error) {
      console.error("Failed to generate report:", error);
    }
  };

  const handleModalSubmit = () => {
    setShowTitleModal(false);
    generateReport(reportTitle.trim());
    setReportTitle(""); // Reset title input
  };

  return (
    <MainSideBar>
      <Grid container spacing={3}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => setShowTitleModal(true)}
            disabled={success}
            style={{ marginTop: "8px", marginBottom: "8px" }}
          >
            {success ? "Report Generated" : "Generate a report"}
          </Button>
        </Grid>

        <Comparison
          categories={categories}
          barChartData={comparisonData.bar_chart_data}
          comparisonData={comparisonData.comparison_data}
        />

        {/* Title Input Modal */}
        <Dialog open={showTitleModal} onClose={() => setShowTitleModal(false)}>
          <DialogTitle>Enter Report Title</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter a title for your report. If left empty, a default title will be used.
            </DialogContentText>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                label="Report Title"
                value={reportTitle}
                onChange={(e) => setReportTitle(e.target.value)}
                placeholder={`Default: Comparison - ${getCurrentDate()}`}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTitleModal(false)}>Cancel</Button>
            <Button onClick={handleModalSubmit} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={success}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            Report generated successfully!
          </Alert>
        </Snackbar>
      </Grid>
    </MainSideBar>
  );
};

export default SentimentBarChart;