import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import {useParams } from "react-router-dom";
import {useDispatch} from "react-redux"
import CreateElement from '../Utils/ElementCreator';
import { useTheme } from '@emotion/react';
import { apiSlice, useAddReviewMutation, useGetReviewDataQuery } from '../Services/ApiServices';
import FetchLoading from './Messages/FetchLoading';
import ClientCreateElement from '../Utils/ClientElementCreator';
import ConfirmMessage from './Messages/ConfirmMessage';
import { MessageGenerator, msg, type } from './Messages/MesageGenerator';


const message = "Thank you, dear valued client, for providing us with your feedback on our service and hospitality. Your feedback is highly valued."
const messageerr = "Your feed back is not submitted. please try Again."
const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: '50%',
  margin: 'auto',
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper, // Match background color
  boxShadow: theme.shadows[3], // Subtle shadow
  borderRadius: theme.shape.borderRadius,
  fontFamily: 'Roboto, sans-serif', // Use Roboto font
  display: 'flex',
  fontSize: 'medium',
  flexDirection: 'column',
  alignItems: 'center', // Center elements within the card
  justifyContent: 'center', // Vertically center elements within the card
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2), // Add margin after content
  width: '100%', // Make button responsive (full width)
  backgroundColor: theme.palette.primary.main, // Use primary color
  color: theme.palette.primary.contrastText, // White text
  '&:hover': {
    backgroundColor: theme.palette.primary.dark, // Darken on hover
  },
}));

const ClientReviewForm = () => {
  const dispatch = useDispatch()
  const [mainUserInput, setMainUser] = useState([])
  const [formData, setFormData] = useState([])
  const [userInput, setUserInput] = useState([])
  const theme = useTheme()
  const [branchName, setBranchName] = useState("")
  const { branchID } = useParams();
  const [addReview, { isLoading: loadings, isSuccess: added, error: errs, data: addData }] = useAddReviewMutation();
  const { data, refetch, isLoading, isSuccess, isError, error } = useGetReviewDataQuery(branchID);
  const [err, setErr] = useState({error: false, message: ""})
  const [loading, setLoading] = useState(true)
  const formRef = useRef(null);



  useEffect(()=>{
    updater()
  },[])

  const updater = async () =>{
    dispatch(apiSlice.util.resetApiState())
    const res = await refetch({ forceRefetch: true }).then((response)=>{
      setLoading((prev) =>{ return false})
      if(response.isError) {
        setLoading((prev) =>{ return false})
        setErr((prev) => { return {...prev, error: true, message: response}})
      }
      else if(response.isSuccess) {
      let data = response.data.data
      setBranchName((prev)=>{return data.branchName})
      setFormData((prev)=>{return data.attributes.map((item) =>{return {...item, value: ""}})})
      setUserInput((prev)=>{return data.attributes.map((item) =>{return {id: item.id, value: ""}})})
          }

  })
}




const clearForm = () => {
    setFormData((prev) => {
      return prev.map((item) => {
        return {...item, value: ""}
      })
    })
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const review = {}
    formData.map((dataItme)  => {
      review[dataItme.title] = dataItme.value
        })
    const reviewData = {
      tenantId:data.data.tenantId,
      branchId:data.data.branchId,
      review:review
    }
    const resp = await addReview(reviewData).then((response) =>{
      if(response.data) {
        clearForm()
      }
    })
  }

  return (
    <>
    {
      loading ? <FetchLoading/> :
      <>
      <Box sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}>  {/* Added mt: 5 for margin-top */}
      {added && <ConfirmMessage message = {message} head={<MessageGenerator message={msg.clientReviewSucc} type={type.suc}/>} />}
      {err.error && <ConfirmMessage message = {messageerr} head={<MessageGenerator message={msg.clientReviewErr} type={type.err}/>} />}
      <StyledCard>
        <CardContent>
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
            {branchName || "Client"} Review Form
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Share your feedback with us!
          </Typography>
        </CardContent>
        <form ref={formRef} noValidate style={{ width:"100%" }} autoComplete="off" onSubmit={handleSubmit}>
          <div style={{ width:"100%", display:"flex", justifyContent:"center" }}> 
          </div>
          {
            formData.map((item)=>{
                return ClientCreateElement(item, setFormData, theme, userInput, setUserInput)
            })
          }
          {loading && <div style={{ display: "flex", justifyContent:"center" }}>
              <FetchLoading/>
            </div>  
          }
          <StyledButton variant="contained" type="submit">
            Submit Review
          </StyledButton>
        </form>
      </StyledCard>
    </Box>
    </>
    }
    </>
  );
};

export default ClientReviewForm;
