import { createSlice } from "@reduxjs/toolkit";

const yearsSlice = createSlice({
  name: "years",
  initialState: {
    years: [], // Array to store years
  },
  reducers: {
    setYears: (state, action) => {
      state.years = action.payload; // Update years in the state
    },
  },
});

export const { setYears } = yearsSlice.actions;
export default yearsSlice.reducer;