// import React, { useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import { useDispatch, useSelector } from 'react-redux';
// import { apiSlice, useAddRegionMutation, useAssignHeadMutation, useAssignManagerMutation, useCreateHeadMutation, useGetHeadsQuery, useGetManagersQuery, usePostAdminMutation, usePostManagerMutation } from '../../../Services/ApiServices';
// import FetchLoading from '../../../Components/Messages/FetchLoading';
// import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
// import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
// import { Add, CloseOutlined } from '@mui/icons-material';

// export default function AssignManager({open, data, closer, updater}) {
//     const dispatch = useDispatch()
//     const { id, name, tenantId } = data
//     const [mutate, { isLoading, isSuccess,isError, error }] = useAssignManagerMutation();
//     const [err, setErr] = useState({error: false, message: ""})
//     const [loading, setLoading] = useState(true)
//     const { data: newData, refetch, isLoading: headLoading, error: headError } = useGetManagersQuery();
//     console.log("new dars ", newData);
//     const [msg, setMsg] = useState({suc: false, message: ""})
//     const [headsList, setHeadssList] = useState([])
//     const getHeads = async () =>{
//       dispatch(apiSlice.util.resetApiState())
//       await refetch({ forceRefetch: true }).then((response)=>{
//         console.log("Fetching managers .... ", response)
//       if(response.isError) {
//         setLoading((prev) =>{ return false})
//         setErr((prev) => { return {...prev, error: true, message: response}})
//       }
//       if(response.isSuccess) {
//         setHeadssList((prev) =>{
//           return response.data.data.managers.map((item)=>{
//               return {
//             id: item.id,
//             firstName: item.firstName,
//             lastName: item.lastName,
//         }
//           })
//         })
       
//         setLoading((prev) =>{ return false})
//       }
//     })
    
//   }


//     useEffect(()=>{
//         getHeads()
//     },[])

//   const [formData, setFormData] = useState({
//     name: name,
//     regionId: id,
//     headId: ''
//   });

  
//   const handleSelect = (event) => {
//     setFormData((prev) =>  {return {...prev, headId: event.target.value}})
//   };
  

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const { regionId, headId } = formData;
//     console.log("to senddddddddddddd", { bra: id, headId});
//     const resp = await mutate({ branchId: id, managerId:headId}).then((response) =>{
//           if(response.data) {
//             setMsg((prev) => {return {...prev, suc: true, message: response.data.data.message}})
//             updater()
//           }
//           if(response.error) {
//             setErr((prev) =>{ return {...prev, error: true, message: response.error.data.message }})
//           }
//         })
//     updater()
//   };
//   return (
//     <>
//       <Dialog open={open} PaperProps={{ component: 'form' }}>
//         <div style={{ padding:"1%" }}>
//         <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
//         <div style={{ width:"90%" }}>
//         <DialogTitle>Assign Manager</DialogTitle>
//         </div>
//         <div>
//           <IconButton color='error' >
//             <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer()}} />
//           </IconButton>
//         </div>
//         </div>
//         {!(msg.suc || err.error) && <DialogContent>
//           <DialogContentText>
//             To Assign Manager please choose from the list.
//           </DialogContentText>
//           <TextField
//             autoFocus
//             required
//             margin="dense"
//             id="name"
//             name="name"
//             label="Region Name"
//             type="text"
//             fullWidth
//             variant="standard"
//             value={name}
//           />
//           <FormControl fullWidth required style={{ marginTop: "10px" }}>
//             <InputLabel  htmlFor="input-type-label">Managers</InputLabel>
//                 <Select
//                 onChange={handleSelect}
//                 value={formData.headId}
//                 name="head"
//                 required 
//                 size='small'
//                 label="regionHead"
//                 labelId="input-type-label">
//                 {newData &&
//                     headsList.map((item) => {
//                         return <MenuItem key={item.id} name={item.id}  value={item.id}>
//                     {`${item.firstName} ${item.lastName}`}
//                     </MenuItem>
//                     })
//                 }
//             </Select>
//             </FormControl>
//         </DialogContent>}
//         <div style={{ display: "flex", justifyContent:"center" }}>
//         {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
//         {(msg.suc && !isLoading)  && <MessageGenerator type={type.suc} message={msg.message} />}
//         {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
//         </div>
//         <DialogActions>
//           <Button onClick={()=>{closer()}}>Cancel</Button>
//           {!(msg.suc || err.error) && <Button type="submit" variant='contained' onClick={handleSubmit}>
//             Assign Manager
//           </Button>}
//         </DialogActions>
//         </div>
//       </Dialog>
//     </>
//   );
// }


import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { apiSlice, useAssignManagerMutation } from '../../../Services/ApiServices';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
export default function AssignManager({ open, data, closer, updater }) {
  const dispatch = useDispatch();
  const { id, name } = data;
  const [mutate, { isLoading, isSuccess, isError, error }] = useAssignManagerMutation();
  const [err, setErr] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(true);
  const [headsList, setHeadsList] = useState([]);
  const [formData, setFormData] = useState({
    name: name,
    headId: ''
  });
  const navigate = useNavigate();
  const [msg, setMsg] = useState({ suc: false, message: "" });

  const getHeads = async () => {
    try {
      const response = await fetch("http://161.35.229.180/api/users/getManagers", {
        method: "GET",
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json"
        }
      });

      if (response.ok) {
        const result = await response.json();
        if (result.success) {
          setHeadsList(result.data.managers.map((item) => ({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
          })));
        } else {
          setErr({ error: true, message: "Failed to fetch managers." });
        }
      } else {
        if (error.response?.status === 400 || error.response?.status === 440) {
          sessionStorage.removeItem("token"); // Clear token
          navigate("/login"); // Redirect to login page
        } else {
          setErr({ error: true, message: `Error: ${response.status} - ${response.statusText}` });

        }
      }
    } catch (err) {
      setErr({ error: true, message: err.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHeads();
  }, []);

  const handleSelect = (event) => {
    setFormData((prev) => ({ ...prev, headId: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { headId } = formData;

    try {
      const resp = await mutate({ branchId: id, managerId: headId }).unwrap();
      if (resp.data) {
        setMsg({ suc: true, message: resp.data.message });
        updater();
      }
    } catch (err) {
      setErr({ error: true, message: err.message });
    }
  };

  return (
    <Dialog open={open} onClose={closer} PaperProps={{ component: 'form' }}>
      <div style={{ padding: "1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
          <div style={{ width: "90%" }}>
            <DialogTitle>Assign Manager</DialogTitle>
          </div>
          <div>
            <IconButton color='error' onClick={closer}>
              <CloseOutlined style={{ margin: "5px" }} />
            </IconButton>
          </div>
        </div>
        {loading ? (
          <FetchLoading />
        ) : err.error ? (
          <div>Error: {err.message}</div>
        ) : (
          <DialogContent>
            <DialogContentText>
              To assign a manager, please choose from the list.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="name"
              label="Region Name"
              type="text"
              fullWidth
              variant="standard"
              value={name}
              InputProps={{ readOnly: true }}
            />
            <FormControl fullWidth required style={{ marginTop: "10px" }}>
              <InputLabel htmlFor="head-select">Managers</InputLabel>
              <Select
                onChange={handleSelect}
                value={formData.headId}
                name="head"
                required
                size='small'
                label="Managers"
                labelId="head-select"
              >
                {headsList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {`${item.firstName} ${item.lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isLoading && <CircularProgress style={{ margin: "auto 0" }} />}
          {(msg.suc && !isLoading) && <MessageGenerator type={type.suc} message={msg.message} />}
          {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <DialogActions>
          <Button onClick={closer}>Cancel</Button>
          {!msg.suc && !err.error && <Button type="submit" variant='contained' onClick={handleSubmit}>
            Assign Manager
          </Button>}
        </DialogActions>
      </div>
    </Dialog>
  );
}
