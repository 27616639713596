import { useSelector, useDispatch } from "react-redux";
import { useGetReportDetailQuery, useDeleteReportMutation } from "../../../Services/ApiServices";
import { Grid, Box, Button, Snackbar, Alert } from "@mui/material";
import MainSideBar from "../../../Components/SideBar";
import { useState, useEffect } from "react";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import Displayer from "../../../Components/HotelInfo/Displayer";
import ApexDonutChartWithDetails from "../../../Components/HotelInfo/ApexDonutChartWithDetails";
import BarChart from "../../../Components/HotelInfo/Barchart";
import AiTable from "../../../Components/HotelInfo/AiTable";
import Comparison from "../../../Components/Comparison";
import { removeReport } from "../../../App/features/reportDetailSlice";
import { useNavigate } from "react-router-dom";

export default function ReportDetail() {
  const reportId = useSelector((state) => state.report.id);
  const { data, isLoading, isError } = useGetReportDetailQuery(reportId);
  const [report, setReport] = useState(null);
  const [deleteReport, { isLoading: isDeleting }] = useDeleteReportMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    if (data) {
      setReport(data.data.report);
    }
  }, [data]);

  if (isLoading) {
    return <FetchLoading />;
  }

  if (isError) {
    return (
      <MainSideBar>
        <div>Error occurred. Please Try Again</div>
      </MainSideBar>
    );
  }

  if (!report) {
    return <FetchLoading />;
  }

  // Handle report deletion
  const deleteReports = async () => {
    try {
      await deleteReport(reportId).unwrap();
  
      // // Clear report ID from Redux
      // dispatch(removeReport(reportId));
  
      // Show success message
      setSnackbarMessage("Report deleted successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
  
      // Wait briefly before navigating to avoid fetching deleted report
      setTimeout(() => {
        navigate("/reports");
      }, 2000);
    } catch (err) {
      // Show error message
      setSnackbarMessage("Failed to delete report. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };
  

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <MainSideBar>
      {/* Snackbar for success/error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={3}>
        {/* Report Title and Delete Button */}
        <Grid item xs={12} container justifyContent="space-between">
          <h3>{report.title}</h3>
          <Button
            variant="outlined"
            onClick={deleteReports}
            disabled={isDeleting}
            style={{ marginTop: "8px", marginBottom: "8px" }}
          >
            {isDeleting ? "Deleting..." : "Delete"}
          </Button>
        </Grid>

        {/* Summary Report Components */}
        {report.type === "Summary" && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Displayer hotel={report.report.hotel} />
            </Grid>
            <Grid item xs={12}>
              <BarChart data={report.report.graphData.reviewsForLineGraph} />
            </Grid>
            <Grid item xs={12}>
              <ApexDonutChartWithDetails
                sentiments={report.report.graphData.sentiments}
                tenantVars={report.report.graphData.tenant_sentiments_list}
              />
            </Grid>
            <Grid item xs={12}>
              <AiTable nexusState={report.report.hotel} />
            </Grid>
          </Grid>
        )}

        {/* Comparison Report Components */}
        {report.type === "Comparison" && (
          <Grid container spacing={3} style={{ margin: "8px auto" }}>
            <Comparison
              categories={report.report.categories}
              barChartData={report.report.barChartData}
              comparisonData={report.report.comparisonData}
            />
          </Grid>
        )}
      </Grid>
    </MainSideBar>
  );
}
