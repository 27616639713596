import { useEffect, useState } from "react";
import { apiSlice, useGetClientReviewQuery } from "../../Services/ApiServices";
import { useDispatch, useSelector } from "react-redux";
import { addClientReview } from "../../App/features/clientReview";
import FetchLoading from "../../Components/Messages/FetchLoading";
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import MainSideBar from "../../Components/SideBar";
import DataGridTable from "../../Components/Datagrid";


function InfoCard ({open, closer, data}) {
    function KeyValueList() {
        if (!data || Object.keys(data).length === 0) {
            return <p>No data to display.</p>;
        }

        return (
            <div>
            {Object.entries(data).map(([key, value]) => (
                <p key={key}>
                <b>{key}:</b> {JSON.stringify(value, null, 2)}
                </p>
            ))}
            </div>
        );
        }
        
    return (<>
        {data && <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <DialogContent>
          <KeyValueList/>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{closer((prev)=>{return false})}}>Close</Button>
        </DialogActions>
        </div>
      </Dialog>}
    </>)
}

export default function ClientReview() {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [comment, setData] = useState({});
  const clientComment = useSelector(state => state.client)
  const { data, refetch, isLoading, isError, error } = useGetClientReviewQuery();

  const updater = async () => {
      dispatch(apiSlice.util.resetApiState())
      await refetch().unwrap()
  }

  useEffect(() => {
      updater()
  }, [])

  // Update Redux store when data is available
  useEffect(() => {
      if (data?.data?.reviews) {
          dispatch(addClientReview(data.data.reviews))
      }
  }, [data, dispatch])
    const columns = [
  {
    field: 'place_name',
    headerName: 'Place Name',
    width: 300,
    editable: false,
  },
  {
    field: 'origin',
    headerName: 'Origin',
    width: 300,
    editable: false,
  },
  {
    field: 'rating',
    headerName: 'Rating',
    width: 250,
    editable: false,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 200,
    editable: false,
    renderCell: (params) => {
            return (
                <>
                    <Button color='success'
                     onClick={()=>{
                        setOpen((prev) => { return true})
                        setData((prev) => {return params.row})
                    }}>See All</Button>
                </>
            )}
          },  
        ];

const dataCompiler = () =>{
    return clientComment.map((item, index) =>{
        return {...item, id: index}
    })
}






return (
  <MainSideBar>
      <InfoCard open={open} closer={setOpen} data={comment}/>
      <DataGridTable 
          title={"Client Reviews"} 
          data={dataCompiler()} 
          columns={columns}
          loading={isLoading}  // Pass loading state here
      />
  </MainSideBar>
)

}