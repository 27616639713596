import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios"; // Import axios
import LoadingSpinner from "../Messages/LoadingSpinner";
import ChartPage from "./LineGraph"; // Assuming LineGraph can handle both line and donut charts
import ApexDonutChartWithDetails from "./ApexDonutChartWithDetails"; // Import the donut chart component
import BarChart from "./Barchart";
import { setGraphData } from "../../App/features/graphDataSlice";
import { useNavigate } from "react-router-dom";
export default function NexusData() {
  const dispatch = useDispatch();
  const hotelId = useSelector((state) => state.detail.id);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState({ error: false, message: "" });
  const [nexusData, setNexusData] = useState(null);
  const navigate = useNavigate();
  const getToken = () => {
    let token = "";
    if (sessionStorage.getItem("token")) {
      token = sessionStorage.getItem("token");
    }
    return token;
  };

  const getHeaders = () => {
    const commonHeaders = {
      "Content-Type": "application/json",
      Accept: "*/*",
      credentials: true,
      Authorization: getToken(),
      Connection: "Keep-alive",
      "Accept-Encoding": "gzip, deflate, br",
    };
    return commonHeaders;
  };
  const fetchNexusData = async () => {
    if (hotelId) {
      setLoading(true);
      try {
        const headers = { ...getHeaders() }; // Add headers here if needed
        const response = await axios.get(
          `http://161.35.229.180/api/reviewAnalysis/analysis/detail/nexus/${hotelId}`,
          { headers }
        );


        if (response.data) {
          let data;
          if (typeof response.data === "string") {
            // Clean the response string if it has a "data:" prefix
            const jsonString = response.data.startsWith("data:")
              ? response.data.replace("data:", "")
              : response.data;
            data = JSON.parse(jsonString); // Parse the cleaned JSON string
          } else {
            data = response.data; // Use directly if already an object
          }

          // Set the parsed data to state
          setNexusData(data.data); // Assuming the JSON structure has a `data` field
          dispatch(setGraphData(data.data));

        } else {
          setErr({ error: true, message: "No data received" });
        }
      } catch (error) {
        console.error("Error:", error); // Log the full error
        
        if (error.response?.status === 400 || error.response?.status === 440) {
          sessionStorage.removeItem("token"); // Clear token
          navigate("/login"); // Redirect to login page
        } else {
          setErr({ error: true, message: error.message });
        }
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setErr({ error: true, message: "No hotelId found" });
    }
  };

  useEffect(() => {
    fetchNexusData();
  }, [hotelId]);

  // Extract data for charts
  const reviewsForLineGraph = nexusData?.reviewsForLineGraph || [];
  // const sentiments = nexusData?.reviewsForLineGraph || [];

  const sentiments = nexusData?.sentiments || {};
  const tenant_sentiments_list = nexusData?.tenant_sentiments_list || {};

  // Prepare dynamic data for the donut chart
  // const sentimentData = Object.keys(sentiments);
  return (
    <Box width={"100%"}>
      {loading ? (
        <LoadingSpinner />
      ) : err.error ? (
        <div>Error: {err.message}</div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            {/* <ChartPage
              reviewsForLineGraph={reviewsForLineGraph}
              sentimentsForLineGraph={sentimentsForLineGraph}
            /> */}
          </Grid>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            <BarChart data={reviewsForLineGraph} />
          </Grid>
          <Grid item xs={12} xl={12} lg={12} md={12}>
            {/* <Grid container spacing={2}> */}
            <ApexDonutChartWithDetails
              sentiments={sentiments}
              tenantVars={tenant_sentiments_list}
            />
            {/* </Grid> */}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
