import { useEffect, useState } from "react";
import MainSideBar from "../../../Components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useGetReportQuery } from "../../../Services/ApiServices";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import { Card, Grid, useTheme } from "@mui/material";
import ReportCard from "../../../Components/ReportCard";
import NoData from "../../../Components/NoData";

export default function Report() {
  const theme = useTheme();

  // Modified query with caching disabled and auto-refecth on mount
  const { 
    data, 
    isLoading, 
    isError, 
    error, 
    refetch // Get the refetch function
  } = useGetReportQuery(undefined, {
    refetchOnMountOrArgChange: true, // Force fresh data on mount
    // Alternatively disable caching completely:
    // refetchOnMountOrArgChange: 0.1 // 0.1 second cache time
  });

  const [reports, setReports] = useState([]);

  // Add manual refetch trigger on component mount
  useEffect(() => {
    refetch();
  }, [refetch]); // Empty dependency array = run only on mount

  // Update reports state when data changes
  useEffect(() => {
    if (data) {
      setReports(data.data.reports);
    }
  }, [data]);

  // ... rest of the component remains the same ...
  // Keep all existing styles and JSX structure

  return (
    <MainSideBar>
      <>
        {isLoading ? (
          <FetchLoading />
        ) : isError ? (
          <div>Error: {error.message}</div>
        ) : (
          <>
            {reports.length > 0 ? (
              <Grid container spacing={2} display={"flex"}>
                {reports.map((item) => (
                  <ReportCard
                    theme={theme}
                    key={item.id}
                    getDetails={() => {
                      console.log(item.id);
                    }}
                    title={item.title}
                    description={item.type}
                    image={item.imageUrl}
                    id={item.id}
                  />
                ))}
              </Grid>
            ) : (
              <NoData message={"No Reports Yet."} />
            )}
          </>
        )}
      </>
    </MainSideBar>
  );
}