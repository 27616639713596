import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { useAddBranchMutation, usePostAdminMutation } from '../../../Services/ApiServices';
import FetchLoading from '../../../Components/Messages/FetchLoading';
import { MessageGenerator, msg, type } from '../../../Components/Messages/MesageGenerator';
import { Alert, CircularProgress, IconButton } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';

export default function BranchCreator({open, closer, updater, data}) {
  const [url, setUrl] = useState("")
//   const [open, setOpen] = useState(false);
  const [err, setErr] = useState({error: false, message: ""})
  const [msg, setMsg] = useState({suc: false, message: ""})
    const [mutate, { isLoading, isSuccess,isError, error }] = useAddBranchMutation();
  const regionId = useSelector(state => state.user.regionId)
  const [formData, setFormData] = useState({
    name: data.name,
    placeId: data.id,
    regionId: regionId
  });
  const handleChange =async (event) => {
    setErr((prev) => {return {...prev, error: false, message:""}})
    setMsg((prev) => {return {suc: false, message: "" }})
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  function extractLatLng(url) {
  const parts = url.split('@')[1].split(',');
  const latitude = parseFloat(parts[0]);
  const longitude = parseFloat(parts[1]);
  return { latitude, longitude };
}


  const handleSubmit = async (event) => {
    event.preventDefault();
    const info =  { name: data.name , placeId: data.id, };
    const response = await mutate({...info})
        if(response.data) {
          setErr((prev) => {return {...prev, error: false, message:""}})
          setMsg((prev) => {return {suc:true, message: response.data.data.message }})
          // setFormData((prev) =>{ return {
          //       name: data.name,
          //       placeId: data.id,
          //       regionId: regionId
          // }})
          updater()
        }
        if(response.error) {
          setMsg((prev) => {return {suc:false, message: "" }})
          setErr((prev) => {return {...prev, error: true, message: response.error.data.data.message}})
        }
  };

  return (
    <>
    
      <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle>Add Branch</DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        <DialogContent>
          <DialogContentText>
            To Add Branch please confirm the following information.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="branchName"
            name="name"
            label="Branch Name"
            type="text"
            fullWidth
            variant="standard"
            value={data.name}
          />
          <TextField
            required
            margin="dense"
            id="placeId"
            name="placeId"
            label="Place ID"
            type="text"
            fullWidth
            variant="standard"
            value={data.id}
            />
          <TextField
            required
            margin="dense"
            id="url"
            name="placeUrl"
            label="Place URL"
            type="text"
            fullWidth
            variant="standard"
            value={url}
            onChange={(e) =>{
              setUrl((prev) =>{ return e.target.value})
            }}
            />
          
        </DialogContent>
        <div style={{ display: "flex", justifyContent:"center" }}>
        {isLoading && <CircularProgress style={{ margin:"auto 0" }} />}
        {(msg.suc && !isLoading)  && <MessageGenerator type={type.suc} message={msg.message} />}
        {(err.error && !isLoading) && <MessageGenerator type={type.err} message={err.message} />}
        </div>
        <DialogActions>
          <Button onClick={()=>{closer((prev)=>{return false})}}>Cancel</Button>
          <Button type="submit" variant='contained' onClick={handleSubmit}>
            Create Branch
          </Button>
        </DialogActions>
            </div>
      </Dialog>
    </>
  );
}