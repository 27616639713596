import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  apiSlice,
  useGetNexusAnaysisQuery,
  useGetGoogleAnaysisQuery,
  useGetOpenAIAnaysisQuery,
  useAddReportMutation,
} from "../Services/ApiServices";
import { addHotel } from "../App/features/hotelDetail";
import MainSideBar from "./SideBar";
import FetchLoading from "./Messages/FetchLoading";
import { addGoogleData } from "../App/features/googleDataSlice";
import { addNexusData } from "../App/features/nexusDataSlice";
import GoogleData from "./HotelInfo/GoogleData";
import NexusData from "./HotelInfo/NewNexusData";
import { 
  Button, 
  Grid, 
  Snackbar, 
  Alert, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  TextField, 
  Box 
} from "@mui/material";
import OpenAiData from "./HotelInfo/OpenAIData";

export default function HotelInfoLayout() {
  const dispatch = useDispatch();
  const hotelId = useSelector((state) => state.detail.id);
  const tenantId = useSelector((state) => state.user.tenantId);

  const [loading, setLoading] = useState(true);
  const [googleLoading, setGoogleLoading] = useState(true);
  const [openLoading, setOpenLoading] = useState(true);
  const [nexusLoaded, setNexusLoaded] = useState(false);
  const [err, setErr] = useState({ error: false, message: "" });
  const [success, setSuccess] = useState(false);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [reportTitle, setReportTitle] = useState("");

  const hotel = useSelector((state) => state.googleData);
  const reportGraphData = useSelector((state) => state.graphData?.graphData || null);

  const { refetch: nexusRefetch } = useGetNexusAnaysisQuery(hotelId);
  const { refetch: googleRefetch } = useGetGoogleAnaysisQuery(hotelId);
  const { refetch: openAiRefetch } = useGetOpenAIAnaysisQuery(hotelId);
  const [addReport] = useAddReportMutation();

  const updater = async (refetch, stoper, adder) => {
    dispatch(apiSlice.util.resetApiState());

    if (hotelId) {
      try {
        const response = await refetch({ forceRefetch: true });
        stoper(false);

        if (response.isError) {
          setErr({ error: true, message: response });
        }
        if (response.data) {
          dispatch(adder(response.data.data));
        }
      } catch (err) {
        stoper(false);
        setErr({ error: true, message: err.message });
      }
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const generateReport = async (customTitle) => {
    if (!reportGraphData) {
      console.error("graphData is undefined. Cannot generate report.");
      return;
    }

    const defaultTitle = `${hotel.displayName} - ${getCurrentDate()}`;
    const reportDataPayload = {
      title: customTitle || defaultTitle,
      type: "Summary",
      report: {
        hotel: hotel,
        graphData: reportGraphData,
      },
    };

    try {
      const response = await addReport(reportDataPayload).unwrap();
      setSuccess(true);
    } catch (error) {
      console.error("Failed to generate report:", error);
      setErr({ error: true, message: error.message });
    }
  };
  const handleModalSubmit = () => {
    setShowTitleModal(false);
    generateReport(reportTitle.trim());
    setReportTitle("");
  };


  const handleCloseSnackbar = () => {
    setSuccess(false); // Hide the success message
  };

  useEffect(() => {
    const fetchData = async () => {
      if (hotelId) {
        // Fetch Nexus Data First
        await updater(nexusRefetch, setLoading, addNexusData);
        setNexusLoaded(true); // Set Nexus flag to true after data is loaded

        // Fetch Google Data only after Nexus is done
        await updater(googleRefetch, setGoogleLoading, addGoogleData);

        // Finally, Fetch OpenAI Data
        await updater(openAiRefetch, setOpenLoading, addHotel);
      }
    };

    fetchData();
  }, [hotelId, tenantId]);

  if (loading || googleLoading || openLoading) {
    return <FetchLoading />;
  }

  return (
    <MainSideBar>
      <Grid container spacing={3}>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => setShowTitleModal(true)}
            disabled={success}
            style={{ marginTop: "8px", marginBottom: "8px" }}
          >
            {success ? "Report Generated" : "Generate a report"}
          </Button>
        </Grid>

        {/* Add the dialog component */}
        <Dialog open={showTitleModal} onClose={() => setShowTitleModal(false)}>
          <DialogTitle>Enter Report Title</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter a title for your report. If empty, we'll use: {hotel.displayName} - {getCurrentDate()}
            </DialogContentText>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                label="Report Title"
                value={reportTitle}
                onChange={(e) => setReportTitle(e.target.value)}
                placeholder={`${hotel.displayName} - ${getCurrentDate()}`}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTitleModal(false)}>Cancel</Button>
            <Button onClick={handleModalSubmit} variant="contained">
              Generate
            </Button>
          </DialogActions>
        </Dialog>

        {/* Keep the existing grid items for data display */}
        <Grid item xs={12} md={12} lg={12} xl={12}>
          {nexusLoaded && <GoogleData />}
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <NexusData />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          {nexusLoaded && <OpenAiData />}
        </Grid>
      </Grid>

      <Snackbar
        open={success}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Report generated successfully!
        </Alert>
      </Snackbar>
    </MainSideBar>
  );

}