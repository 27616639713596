import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";

// const baseKlaraUrl = "https://klara-backend.vercel.app/api/";
// const baseKlaraUrl = "http://localhost:3002/api/";
const baseKlaraUrl = "http://161.35.229.180/api/";

const getToken = () => sessionStorage.getItem("token") || "";

const getHeaders = () => {
  const commonHeaders = {
    "Content-Type": "application/json",
    Accept: "*/*",
    credentials: true,
    Authorization: getToken(),
    Connection: "Keep-alive",
    "Accept-Encoding": "gzip, deflate, br",
  };
  return commonHeaders;
};
const baseQuery = fetchBaseQuery({ baseUrl: baseKlaraUrl });

const baseQueryWithAuthHandling = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  // Handle 400 and 440 errors
  if (result.error?.status === 400 || result.error?.status === 440) {
    // Clear authentication data
    sessionStorage.removeItem("token");
    // Redirect to login page
    window.location.href = "/login"; // Update with your login route
  }

  return result;
};
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithAuthHandling,
  endpoints: (builder) => ({
    updateHead: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: `users/removeHead/${data}`, // URL with parameter
        method: "PUT",
        body: JSON.stringify({ data }),
      }),
    }),
    editSenti: builder.mutation({
      query: (text) => ({
        headers: { ...getHeaders() },
        url: `sentiments/${text.id}`, // URL with parameter
        method: "PUT",
        body: text.data,
      }),
    }),
    editTenantType: builder.mutation({
      query: (text) => ({
        headers: { ...getHeaders() },
        url: `tenant-types/${text.id}`, // URL with parameter
        method: "PUT",
        body: text.data,
      }),
    }),
    addSentiment: builder.mutation({
      query: (text) => ({
        headers: { ...getHeaders() },
        url: `sentiments`,
        method: "POST",
        body: text,
      }),
    }),
    addTenantType: builder.mutation({
      query: (text) => ({
        headers: { ...getHeaders() },
        url: `tenant-types`, // URL with parameter
        method: "POST",
        body: text,
      }),
    }),
    searchHotel: builder.mutation({
      query: (text) => ({
        headers: { ...getHeaders() },
        url: `/reviewAnalysis/analysis/searchHotel`, // URL with parameter
        method: "POST",
        body: text,
      }),
    }),
    deleteSenti: builder.mutation({
      query: (manId) => ({
        headers: { ...getHeaders() },
        url: `sentiments/${manId}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    deleteTenantTypes: builder.mutation({
      query: (manId) => ({
        headers: { ...getHeaders() },
        url: `tenant-types/${manId}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    deleteManager: builder.mutation({
      query: (manId) => ({
        headers: { ...getHeaders() },
        url: `users/removeManager/${manId}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    deleteHead: builder.mutation({
      query: (headId) => ({
        headers: { ...getHeaders() },
        url: `users/removeHead/${headId}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    deleteFormsList: builder.mutation({
      query: (adminId) => ({
        headers: { ...getHeaders() },
        url: `review-forms//${adminId}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    deleteAdmin: builder.mutation({
      query: (adminId) => ({
        headers: { ...getHeaders() },
        url: `users/removeAdmin/${adminId}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    deleteBranch: builder.mutation({
      query: (brId) => ({
        headers: { ...getHeaders() },
        url: `branches/${brId}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    deleteRegion: builder.mutation({
      query: (regionID) => ({
        headers: { ...getHeaders() },
        url: `regions/${regionID}`, // URL with parameter
        method: "DELETE",
      }),
    }),
    updateBranch: builder.mutation({
      query: (branchData) => ({
        headers: { ...getHeaders() },
        url: `branches/${branchData.id}`,
        method: "PUT",
        body: JSON.stringify(branchData.data),
      }),
    }),
    updateRegion: builder.mutation({
      query: (regionData) => ({
        headers: { ...getHeaders() },
        url: `regions/${regionData.id}`,
        method: "PUT",
        body: JSON.stringify(regionData.data),
      }),
    }),
    updateFormsList: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: `review-forms/${userData.id}`,
        method: "PUT",
        body: JSON.stringify(userData.data),
      }),
    }),
    updateManager: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: `users/updateManager/${userData.id}`,
        method: "PUT",
        body: JSON.stringify(userData.data),
      }),
    }),
    updateHead: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: `users/updateHead/${userData.id}`,
        method: "PUT",
        body: JSON.stringify(userData.data),
      }),
    }),
    updateAdmin: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: `users/updateAdmin/${userData.id}`,
        method: "PUT",
        body: JSON.stringify(userData.data),
      }),
    }),
    createHead: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: "users/postHead",
        method: "POST",
        body: JSON.stringify(userData.formData),
      }),
    }),
    getRegions: builder.query({
      query: () => ({
        url: "regions/",
        headers: { ...getHeaders() },
      }),
    }),
    getTenantType: builder.query({
      query: () => ({
        url: "tenant-types",
        headers: { ...getHeaders() },
      }),
    }),
    getSentiments: builder.query({
      query: () => ({
        url: `sentiments`,
        headers: { ...getHeaders() },
      }),
    }),
    getAdBranches: builder.query({
      query: (id) => ({
        url: `/dashboard/branches/${id}`,
        headers: { ...getHeaders() },
      }),
    }),
    getDashboards: builder.query({
      query: () => ({
        url: "dashboard",
        headers: { ...getHeaders() },
      }),
    }),
    getAllTenants: builder.query({
      query: () => ({
        url: "sys-admin/tenants/",
        headers: { ...getHeaders() },
      }),
    }),
    getClientReview: builder.query({
      query: () => ({
        url: "reviews/fetchReview",
        headers: { ...getHeaders() },
      }),
    }),
    getSentimentSummary: builder.query({
      query: ({ hotelId, reviewPeriods, year, sentiment }) => ({
        url: `reviewAnalysis/analysis/detail/nexus/recalculate-sentiment-summary/${hotelId}`,
        method: "POST",
        headers: getHeaders(),
        body: { review_periods: reviewPeriods, year, sentiment },
      }),
    }),
    getFormsList: builder.query({
      query: () => ({
        url: "review-forms/",
        headers: { ...getHeaders() },
      }),
    }),
    getFormsListById: builder.query({
      query: (id) => ({
        url: `review-forms/${id}`,
        headers: { ...getHeaders() },
      }),
    }),
    getHeads: builder.query({
      query: () => ({
        url: "users/getHeads",
        headers: { ...getHeaders() },
      }),
    }),
    assignManager: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: "branches/assignManager",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    addNewForm: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: "review-forms/",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    assignHead: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: "regions/assignHead",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    addRegion: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: "regions/postRegion",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    postBranchComparison: builder.mutation({
      query: (data) => ({
        url: "comparisons/branches",
        method: "POST",
        headers: { ...getHeaders() },
        body: JSON.stringify(data),
      }),
    }),
    postRegionComparison: builder.mutation({
      query: (data) => ({
        url: "/comparisons/regions",
        method: "POST",
        headers: { ...getHeaders() },
        body: JSON.stringify(data),
      }),
    }),
    requestComparision: builder.mutation({
      query: () => ({
        headers: { ...getHeaders() },
        url: "customer-requests/comparison",
        method: "POST",
        body: {},
      }),
    }),
    requestSurvey: builder.mutation({
      query: (surveyData) => ({
        headers: { ...getHeaders() },
        url: "customer-requests/survey",
        method: "POST",
        body: { message: surveyData.message },
      }),
    }),
    postAdmin: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: "users/postAdmin",
        method: "POST",
        body: JSON.stringify(userData.formData),
      }),
    }),
    addBranch: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: "branches/postBranch",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    postTenant: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: "sys-admin/tenants/postTenant",
        method: "POST",
        body: JSON.stringify(userData.formData),
      }),
    }),
    removeSenimentTenant: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: "sentiments/remove-sentiment-tenant-type",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    assignSenimentTenant: builder.mutation({
      query: (data) => ({
        headers: { ...getHeaders() },
        url: "sentiments/assign-sentiment-tenant-type",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    postManager: builder.mutation({
      query: (userData) => ({
        headers: { ...getHeaders() },
        url: "users/postManager",
        method: "POST",
        body: JSON.stringify(userData.formData),
      }),
    }),
    getAdmins: builder.query({
      query: () => ({
        url: "users/getAdmins",
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    getBranches: builder.query({
      query: () => ({
        url: "branches/",
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    getBranchList: builder.query({
      query: () => ({
        url: "comparisons/branches",
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    getManagers: builder.query({
      query: () => ({
        url: "users/getManagers",
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    addReview: builder.mutation({
      query: (data) => ({
        url: "reviews/addReview",
        method: "POST",
        headers: { ...getHeaders() },
        body: JSON.stringify(data),
      }),
    }),
    saveReviewAttributes: builder.mutation({
      query: (data) => ({
        url: `reviewAttributes/${data.id}`,
        method: "POST",
        headers: { ...getHeaders() },
        body: JSON.stringify(data.data),
      }),
    }),
    getReviewData: builder.query({
      query: (branchID) => ({
        url: `reviews/${branchID}`,
        headers: { ...getHeaders() },
      }),
      invalidatesTags: ["getReviewData"],
    }),
    getForms: builder.query({
      query: (id) => ({
        url: `reviewAttributes/${id}`,
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    appLogin: builder.mutation({
      query: (data) => ({
        url: "auth/signin",
        method: "POST",
        body: data,
        headers: { ...getHeaders() },
      }),
    }),
    getNexusAnaysis: builder.query({
      query: (id) => ({
        url: `reviewAnalysis/analysis/detail/nexus/${id}`,
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    getGoogleAnaysis: builder.query({
      query: (id) => ({
        url: `reviewAnalysis/analysis/detail/google/${id}`,
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    getOpenAIAnaysis: builder.query({
      query: (id) => ({
        url: `reviewAnalysis/analysis/detail/openai/${id}`,
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    // In your apiSlice.js
    getNexusData: builder.query({
      query: (id) => ({
        url: `reviewAnalysis/analysis/detail/nexus/${id}`,
        headers: getHeaders(),
      }),
      transformResponse: (response) => {
        try {
          // Handle both string and object responses
          const rawData =
            typeof response === "string"
              ? JSON.parse(
                  response.startsWith("data:") ? response.slice(5) : response
                )
              : response;

          return rawData.data;
        } catch (error) {
          console.error("Response parsing error:", error);
          throw new Error("Failed to parse response data");
        }
      },
    }),
    postSentimentRecalculation: builder.mutation({
      query: ({ hotelId, reviewPeriods, year, sentiment }) => ({
        url: `reviewAnalysis/analysis/detail/nexus/recalculate-sentiment-summary/${hotelId}`,
        method: "POST",
        headers: getHeaders(),
        body: { review_periods: reviewPeriods, year, sentiment },
      }),
    }),

    addReport: builder.mutation({
      query: (data) => ({
        url: "reports",
        method: "POST",
        body: data,
        headers: { ...getHeaders() },
      }),
    }),
    getReport: builder.query({
      query: () => ({
        url: `reports`,
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    getReportDetail: builder.query({
      query: (id) => ({
        url: `reports/${id}`,
        method: "GET",
        headers: { ...getHeaders() },
      }),
    }),
    deleteReport: builder.mutation({
      query: (id) => ({
        url: `reports/${id}`,
        method: "Delete",
        headers: { ...getHeaders() },
      }),
    }),
  }),
});

export const { useAppLoginMutation } = apiSlice;
export const { useAddNewFormMutation } = apiSlice;
export const { useUpdateFormsListMutation } = apiSlice;
export const { useGetNexusAnaysisQuery } = apiSlice;
export const { useGetGoogleAnaysisQuery } = apiSlice;
export const { useGetOpenAIAnaysisQuery } = apiSlice;
export const { useGetFormsQuery } = apiSlice;
export const { useGetReviewDataQuery } = apiSlice;
export const { useSaveReviewAttributesMutation } = apiSlice;
export const { useAddReviewMutation } = apiSlice;
export const { useAddSentimentMutation } = apiSlice;
export const { useGetAdminsQuery } = apiSlice;
export const { useGetManagersQuery } = apiSlice;
export const { useGetBranchesQuery } = apiSlice;
export const { useGetBranchListQuery } = apiSlice;
export const { useGetHeadsQuery } = apiSlice;
export const { useGetAdBranchesQuery } = apiSlice;
export const { useGetRegionsQuery } = apiSlice;
export const { useGetSentimentsQuery } = apiSlice;
export const { useGetTenantTypeQuery } = apiSlice;
export const { useGetDashboardsQuery } = apiSlice;
export const { useGetAllTenantsQuery } = apiSlice;
export const { useGetFormsListQuery } = apiSlice;
export const { useGetFormsListByIdQuery } = apiSlice;
export const { useGetClientReviewQuery } = apiSlice;
export const { usePostAdminMutation } = apiSlice;
export const { useEditTenantTypeMutation } = apiSlice;
export const { useAddTenantTypeMutation } = apiSlice;
export const { useAddRegionMutation } = apiSlice;
export const { useDeleteHeadMutation } = apiSlice;
export const { useDeleteAdminMutation } = apiSlice;
export const { useDeleteFormsListMutation } = apiSlice;
export const { useDeleteTenantTypesMutation } = apiSlice;
export const { useDeleteManagerMutation } = apiSlice;
export const { useRequestComparisionMutation } = apiSlice;
export const { useDeleteRegionMutation } = apiSlice;
export const { useDeleteSentiMutation } = apiSlice;
export const { useDeleteBranchMutation } = apiSlice;
export const { useAssignHeadMutation } = apiSlice;
export const { useSearchHotelMutation } = apiSlice;
export const { useAssignManagerMutation } = apiSlice;
export const { useCreateHeadMutation } = apiSlice;
export const { usePostManagerMutation } = apiSlice;
export const { usePostTenantMutation } = apiSlice;
export const { useUpdateHeadMutation } = apiSlice;
export const { useAssignSenimentTenantMutation } = apiSlice;
export const { usePostBranchComparisonMutation } = apiSlice;
export const { usePostRegionComparisonMutation } = apiSlice;
export const { useRemoveSenimentTenantMutation } = apiSlice;
export const { useUpdateAdminMutation } = apiSlice;
export const { useUpdateManagerMutation } = apiSlice;
export const { useRequestSurveyMutation } = apiSlice;
export const { useEditSentiMutation } = apiSlice;
export const { useUpdateRegionMutation } = apiSlice;
export const { useUpdateBranchMutation } = apiSlice;
export const { useAddBranchMutation } = apiSlice;
export const { useGetSentimentSummaryQuery } = apiSlice;
export const { useGetNexusDataQuery } = apiSlice;
export const { useAddReportMutation } = apiSlice;
export const { useDeleteReportMutation } = apiSlice;
export const { useGetReportQuery } = apiSlice;
export const { useGetReportDetailQuery } = apiSlice;
export const { usePostSentimentRecalculationMutation } = apiSlice;
