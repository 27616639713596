import React, { useState, useMemo, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setYears } from "../../App/features/yearsSlice"; // Import the action

const RatingChart = ({ data }) => {
  
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedInterval, setSelectedInterval] = useState("annually"); // Default to "annually"
  
  // Extract unique years from the data
  const years = useMemo(() => {
    const uniqueYears = new Set();
    data.forEach((item) => {

      const year = new Date(item.published_date).getFullYear();
      uniqueYears.add(year);

    });
    return Array.from(uniqueYears).sort();
  }, [data]);

  // Dispatch the years to the Redux store
  useEffect(() => {
    dispatch(setYears(years)); // Update the years in the Redux store
  }, [years, dispatch]);

  // Filter data based on the selected year (if not "annually")
  const filteredData = useMemo(() => {
    if (selectedInterval === "annually") return data; // Show all data for "annually"
    if (!selectedYear) return [];
    return data.filter((item) => {
      const year = new Date(item.published_date).getFullYear();
        
      return year === parseInt(selectedYear);
    });
  }, [data, selectedYear, selectedInterval]);

  // Group data based on the selected interval
  const groupedData = useMemo(() => {
    const groups = {};

    filteredData.forEach((item) => {

      const date = new Date(item.published_date);
      
      let key;

      if (selectedInterval === "monthly") {
        key = `${date.getFullYear()}-${date.toLocaleString("default", { month: "long" })}`;
      } else if (selectedInterval === "quarterly") {
        const quarter = Math.floor((date.getMonth() + 3) / 3);
        key = `${date.getFullYear()}-Q${quarter}`;
      } else if (selectedInterval === "semi-annually") {
        const half = date.getMonth() < 6 ? "H1" : "H2";
        key = `${date.getFullYear()}-${half}`;
      } else if (selectedInterval === "annually") {
        key = date.getFullYear().toString();
      }

      if (!groups[key]) {
        groups[key] = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
      }
      groups[key][item.rating]++;
    });

    return groups;
  }, [filteredData, selectedInterval]);

  // Sort months chronologically
  const sortCategories = (categories) => {
    if (selectedInterval === "monthly") {
      const monthOrder = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      return categories.sort((a, b) => {
        const aMonth = a.split("-")[1];
        const bMonth = b.split("-")[1];
        return monthOrder.indexOf(aMonth) - monthOrder.indexOf(bMonth);
      });
    }
    return categories.sort();
  };

  // Prepare chart data
  const chartData = useMemo(() => {
    const categories = sortCategories(Object.keys(groupedData));

    const series = [1, 2, 3, 4, 5].map((rating) => ({
      name: `${rating} Star`,
      data: categories.map((category) => {
        const total = Object.values(groupedData[category]).reduce((a, b) => a + b, 0);
        const count = groupedData[category][rating] || 0;
        return total === 0 ? 0 : (count / total) * 100;
      }),
    }));

    return {
      options: {
        chart: {
          type: "bar",
          stacked: false, // Grouped bars instead of stacked
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%", // Adjust the width of the bars
            dataLabels: {
              enabled: false, // Disable data labels on bars
            },
          },
        },
        dataLabels: {
          enabled: false, // Disable data labels globally
        },
        xaxis: {
          categories,
          title: {
            text: selectedInterval === "annually" ? "Year" : selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1),
          },
        },
        yaxis: {
          title: {
            text: "Percentage",
          },
          labels: {
            formatter: (value) => `${value.toFixed(2)}%`,
          },
        },
        tooltip: {
          enabled: true, // Ensure tooltip is enabled
          y: {
            formatter: (value) => `${value.toFixed(2)}%`, // Show percentage in tooltip
          },
        },
        legend: {
          position: "top", // Display legend at the top
        },
      },
      series,
    };
  }, [groupedData, selectedInterval]);

  return (
    <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", position: "relative" }}>
      <div style={{ width: "200px", display: "inline-block" }}>
        <label>Select Interval: </label>
        <Select
          value={selectedInterval}
          onChange={(e) => setSelectedInterval(e.target.value)}
        >
          <MenuItem value="annually">Annually</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="quarterly">Quarterly</MenuItem>
          <MenuItem value="semi-annually">Semi-Annually</MenuItem>
        </Select>
      </div>
      <div style={{ width: "200px", display: "inline-block" }}>
        <label>Select Year: </label>
        <Select
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          disabled={selectedInterval === "annually"} // Disable when "annually" is selected
        >
          <MenuItem value="">Select Year</MenuItem>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </div>
      {selectedInterval && (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      )}
    </div>
  );
};

export default RatingChart;

// import React, { useState, useMemo, useEffect } from "react";
// import ReactApexChart from "react-apexcharts";
// import { MenuItem, Select } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import { setYears } from "../../App/features/yearsSlice"; // Import the action

// const RatingChart = ({ data }) => {
//   console.log("the data", data);

//   const dispatch = useDispatch();
//   const [selectedYear, setSelectedYear] = useState("");
//   const [selectedInterval, setSelectedInterval] = useState("annually"); // Default to "annually"

//   // Extract unique years from the data
//   const years = useMemo(() => {
//     const uniqueYears = new Set();
//     data.forEach((item) => {
//       const year = new Date(item.published_date).getFullYear();
//       uniqueYears.add(year);
//     });
//     return Array.from(uniqueYears).sort();
//   }, [data]);

//   // Dispatch the years to the Redux store
//   useEffect(() => {
//     dispatch(setYears(years)); // Update the years in the Redux store
//   }, [years, dispatch]);

//   // Filter data based on the selected year (if not "annually")
//   const filteredData = useMemo(() => {
//     if (selectedInterval === "annually") return data; // Show all data for "annually"
//     if (!selectedYear) return [];
//     return data.filter((item) => {
//       const year = new Date(item.published_date).getFullYear();
//       return year === parseInt(selectedYear);
//     });
//   }, [data, selectedYear, selectedInterval]);

//   // Group data based on the selected interval
//   const groupedData = useMemo(() => {
//     const groups = {};

//     filteredData.forEach((item) => {
//       const date = new Date(item.published_date);
//       let key;

//       if (selectedInterval === "monthly") {
//         key = `${date.getFullYear()}-${date.toLocaleString("default", { month: "long" })}`;
//       } else if (selectedInterval === "quarterly") {
//         const quarter = Math.floor((date.getMonth() + 3) / 3);
//         key = `${date.getFullYear()}-Q${quarter}`;
//       } else if (selectedInterval === "semi-annually") {
//         const half = date.getMonth() < 6 ? "H1" : "H2";
//         key = `${date.getFullYear()}-${half}`;
//       } else if (selectedInterval === "annually") {
//         key = date.getFullYear().toString();
//       }

//       if (!groups[key]) {
//         groups[key] = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
//       }
//       groups[key][item.rating]++;
//     });

//     return groups;
//   }, [filteredData, selectedInterval]);

//   // Sort months chronologically
//   const sortCategories = (categories) => {
//     if (selectedInterval === "monthly") {
//       const monthOrder = [
//         "January", "February", "March", "April", "May", "June",
//         "July", "August", "September", "October", "November", "December"
//       ];
//       return categories.sort((a, b) => {
//         const aMonth = a.split("-")[1];
//         const bMonth = b.split("-")[1];
//         return monthOrder.indexOf(aMonth) - monthOrder.indexOf(bMonth);
//       });
//     }
//     return categories.sort();
//   };

//   // Prepare chart data
//   const chartData = useMemo(() => {
//     const categories = sortCategories(Object.keys(groupedData));
//     console.log("data", categories);

//     const series = [1, 2, 3, 4, 5].map((rating) => ({
//       name: `${rating} Star`,
//       data: categories.map((category) => {
//         const count = groupedData[category][rating] || 0;
//         return count; // Use the actual count instead of percentage
//       }),
//     }));

//     return {
//       options: {
//         chart: {
//           type: "bar",
//           stacked: false, // Grouped bars instead of stacked
//         },
//         plotOptions: {
//           bar: {
//             horizontal: false,
//             columnWidth: "55%", // Adjust the width of the bars
//             dataLabels: {
//               enabled: false, // Disable data labels on bars
//             },
//           },
//         },
//         dataLabels: {
//           enabled: false, // Disable data labels globally
//         },
//         xaxis: {
//           categories,
//           title: {
//             text: selectedInterval === "annually" ? "Year" : selectedInterval.charAt(0).toUpperCase() + selectedInterval.slice(1),
//           },
//         },
//         yaxis: {
//           title: {
//             text: "Number of Reviews",
//           },
//           labels: {
//             formatter: (value) => `${value}`, // Show actual count
//           },
//         },
//         tooltip: {
//           enabled: true, // Ensure tooltip is enabled
//           y: {
//             formatter: (value) => `${value}`, // Show actual count in tooltip
//           },
//         },
//         legend: {
//           position: "top", // Display legend at the top
//         },
//       },
//       series,
//     };
//   }, [groupedData, selectedInterval]);

//   return (
//     <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", position: "relative" }}>
//       <div style={{ width: "200px", display: "inline-block" }}>
//         <label>Select Interval: </label>
//         <Select
//           value={selectedInterval}
//           onChange={(e) => setSelectedInterval(e.target.value)}
//         >
//           <MenuItem value="annually">Annually</MenuItem>
//           <MenuItem value="monthly">Monthly</MenuItem>
//           <MenuItem value="quarterly">Quarterly</MenuItem>
//           <MenuItem value="semi-annually">Semi-Annually</MenuItem>
//         </Select>
//       </div>
//       <div style={{ width: "200px", display: "inline-block" }}>
//         <label>Select Year: </label>
//         <Select
//           value={selectedYear}
//           onChange={(e) => setSelectedYear(e.target.value)}
//           disabled={selectedInterval === "annually"} // Disable when "annually" is selected
//         >
//           <MenuItem value="">Select Year</MenuItem>
//           {years.map((year) => (
//             <MenuItem key={year} value={year}>
//               {year}
//             </MenuItem>
//           ))}
//         </Select>
//       </div>
//       {selectedInterval && (
//         <ReactApexChart
//           options={chartData.options}
//           series={chartData.series}
//           type="bar"
//           height={350}
//         />
//       )}
//     </div>
//   );
// };

// export default RatingChart;