import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import {
  Alert,
  MenuItem,
  Select,
  LinearProgress,
  Button,
  IconButton,
} from "@mui/material";
import LoadingSpinner from "../Messages/LoadingSpinner";
import ReactDOMServer from "react-dom/server";
import TooltipContent from "./TooltipContent";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Download } from "@mui/icons-material"; // Import the download icon
import { usePostSentimentRecalculationMutation } from "../../Services/ApiServices";

// CSS for grid layout
const useStyles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "20px",
    justifyContent: "center",
  },
  singleChartContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(1, 1fr)",
    justifyContent: "center",
  },
};

const SentimentPieChart = ({ sentiments, tenantVars }) => {
  const chartRef = useRef(null); // Ref to capture the charts
  const years = useSelector((state) => state.years.years);
  const hotelId = useSelector((state) => state.detail.id);
  const hotel = useSelector((state) => state.googleData); // Fetch branch name
  const branchName = hotel.displayName; // Branch name
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedReviewDate, setSelectedReviewDate] = useState("All");
  const [selectedSentiment, setSelectedSentiment] = useState("All");
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isDataFromAPI, setIsDataFromAPI] = useState(false);
  const [validationError, setValidationError] = useState(null);

  // Helper functions
  const [postSentimentRecalculation] = usePostSentimentRecalculationMutation();

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);
    setSelectedReviewDate(year === "All" ? "All" : "Choose Review Date");
    setSelectedSentiment(year === "All" ? "All" : "Choose a Sentiment");
    setApiResponse(null);
  };
  const sortMonths = (months) => {
    const monthOrder = [
      "january", "february", "march", "april", "may", "june",
      "july", "august", "september", "october", "november", "december"
    ];
  
    return months.sort((a, b) => {
      // Extract the month part from the year-month string (e.g., "2017-may" => "may")
      const monthA = a.split("-")[1].toLowerCase(); // Convert to lowercase for case-insensitive comparison
      const monthB = b.split("-")[1].toLowerCase();
  
      // Compare the months based on their order in the monthOrder array
      return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
    });
  };
  const handleReviewDateChange = (event) => {
    setSelectedReviewDate(event.target.value);
    setApiResponse(null);
    setSelectedSentiment("Choose a Sentiment");
  };

  const handleSentimentChange = (event) => {
    setSelectedSentiment(event.target.value);
    setApiResponse(null);
  };

  const isButtonDisabled =
    selectedReviewDate === "Choose Review Date" ||
    selectedReviewDate === "All" ||
    selectedSentiment === "Choose a Sentiment";

    const sendApiRequest = async () => {
      if (selectedYear !== "All" && selectedReviewDate !== "Choose Review Date") {
        setLoading(true);
        setError(null);
        setProgress(0);
        setIsDataFromAPI(true);
  
        try {
          const response = await postSentimentRecalculation({
            hotelId,
            reviewPeriods: selectedReviewDate,
            year: selectedYear,
            sentiment: selectedSentiment,
          }).unwrap();
  
          // Handle streaming response
          const reader = response.body.getReader();
          const decoder = new TextDecoder();
          let result = "";
  
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
  
            result += decoder.decode(value, { stream: true });
            const jsonObjects = result.split("data: ");
            
            for (let i = 0; i < jsonObjects.length - 1; i++) {
              const json = jsonObjects[i].trim();
              if (json) {
                try {
                  const data = JSON.parse(json);
                  if (data.progress !== undefined) {
                    setProgress(data.progress);
                  } else if (data.success) {
                    setApiResponse(data);
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              }
            }
            result = jsonObjects[jsonObjects.length - 1];
            setApiResponse(result);
          }
        } catch (error) {
          console.error("API Error:", error);
          setError("Failed to fetch data. Please try again.");
        } finally {
          setLoading(false);
        }
      }
    };

  const getChartData = (sentimentData) => {
    const categories = ["Great", "Good", "Neutral", "Bad", "Worst"];
    const series = categories.map(
      (category) => sentimentData[category]?.review_counts || 0
    );
    const totalValue = series.reduce((sum, value) => sum + value, 0);

    return {
      series,
      options: {
        chart: {
          type: "pie",
        },
        labels: categories,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        title: {
          text: `Total Reviews: ${totalValue}`,
          align: "center",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
        tooltip: {
          enabled: true,
          custom: ({ seriesIndex, w }) => {
            const category = w.config.labels[seriesIndex];
            const count = w.config.series[seriesIndex];
            const percentage = ((count / totalValue) * 100).toFixed(2);
            const summary =
              sentimentData[category]?.summary || "No summary available.";

            // Render TooltipContent as a string
            const tooltipContent = ReactDOMServer.renderToString(
              <TooltipContent
                sentiment={category}
                count={count}
                percentage={percentage}
                summary={summary}
              />
            );

            return tooltipContent;
          },
        },
      },
    };
  };

  // Function to generate table HTML for PDF
  const generateTableHTML = (sentimentData, sentiment) => {
    const categories = ["Great", "Good", "Neutral", "Bad", "Worst"];
    
    const totalValue = categories.reduce(
      (sum, category) => sum + (sentimentData[category]?.review_counts || 0),
      0
    );

    return `
      <div style="margin-bottom: 20px;">
        <h3 style="font-size: 18px; font-weight: bold; margin-bottom: 10px;">${sentiment}</h3>
        <table style="width: 100%; border-collapse: collapse;">
          <thead>
            <tr>
              <th style="border: 1px solid #000; padding: 8px;">Sentiment</th>
              <th style="border: 1px solid #000; padding: 8px;">Total Reviews Count (Percentage)</th>
              <th style="border: 1px solid #000; padding: 8px;">Summary</th>
            </tr>
          </thead>
          <tbody>
            ${categories
              .map((category) => {
                const count = sentimentData[category]?.review_counts || 0;
                const percentage = ((count / totalValue) * 100).toFixed(2);
                const summary = sentimentData[category]?.summary || "No summary available.";
                return `
                  <tr>
                    <td style="border: 1px solid #000; padding: 8px;">${category}</td>
                    <td style="border: 1px solid #000; padding: 8px; text-align: right;">${count} (${percentage}%)</td>
                    <td style="border: 1px solid #000; padding: 8px;">${summary}</td>
                  </tr>
                `;
              })
              .join("")}
          </tbody>
        </table>
      </div>
    `;
  };

  // Function to handle PDF export
  const handleDownloadPdf = async () => {
    const input = chartRef.current;

    // Create a new PDF
    const pdf = new jsPDF("landscape");
  
    // Add the branch name at the top of the PDF
    pdf.setFontSize(18);
    pdf.text(`Branch Name: ${branchName}`, 10, 20);
  
    // Get all pie chart containers and their corresponding text labels
    const pieChartContainers = input.querySelectorAll(".apexcharts-canvas");
    const pieChartLabels = input.querySelectorAll("h3"); // Assuming the labels are in <h3> tags
  
    // Group pie charts and their labels into sets of 4
    const pieChartGroups = [];
    for (let i = 0; i < pieChartContainers.length; i += 4) {
      const group = Array.from(pieChartContainers).slice(i, i + 4);
      const labels = Array.from(pieChartLabels).slice(i, i + 4);
      pieChartGroups.push({ group, labels });
    }
  
    // Capture each group of 4 pie charts (with labels) as an image and add to the PDF
    for (let groupIndex = 0; groupIndex < pieChartGroups.length; groupIndex++) {
      const { group, labels } = pieChartGroups[groupIndex];
  
      // Create a temporary container for the group of pie charts and labels
      const tempDiv = document.createElement("div");
      tempDiv.style.display = "grid";
      tempDiv.style.gridTemplateColumns = "repeat(2, 1fr)"; // 2 columns for 4 pie charts
      tempDiv.style.gap = "20px";
      tempDiv.style.padding = "20px";
      tempDiv.style.backgroundColor = "white";
  
      // Add labels and pie charts to the temporary container
      labels.forEach((label, index) => {
        const labelClone = label.cloneNode(true);
        const pieChartClone = group[index].cloneNode(true);
  
        // Create a container for the label and pie chart
        const container = document.createElement("div");
        container.appendChild(labelClone);
        container.appendChild(pieChartClone);
  
        tempDiv.appendChild(container);
      });
  
      // Append the temporary container to the document body
      document.body.appendChild(tempDiv);
  
      // Capture the temporary container as an image
      const canvas = await html2canvas(tempDiv);
      const imgData = canvas.toDataURL("image/png");
  
      // Add the image to the PDF
      if (groupIndex > 0) {
        pdf.addPage(); // Add a new page for each group after the first
      }
  
      const imgWidth = 280; // Adjust based on your layout
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 10, 20, imgWidth, imgHeight);
  
      // Clean up the temporary container
      document.body.removeChild(tempDiv);
    }
  
    // Add tables for each chart
    let yPosition = pdf.internal.pageSize.height - 20;
  
    // Render tables for each chart
    let dataToUse;
  
    if (apiResponse) {
      const jsonObjects = apiResponse.split("\n");
      jsonObjects.forEach((json) => {
        const trimmedJson = json.trim(); // Remove extra spaces and newlines
        if (trimmedJson) {
          const jsonString = trimmedJson.startsWith("data:")
            ? trimmedJson.slice(5)
            : trimmedJson;
          const jsonData = JSON.parse(jsonString);
          if (jsonData.success) {
            dataToUse = jsonData.data.sentiments;
          }
        }
      });
    }
    // Prioritize API response data only if it is successful
    if (!dataToUse) {
      dataToUse = sentiments;
    }
  
    // Function to get sentiment data dynamically based on selected filters
   
    // Group pie charts into sets of 4 per page
    const pieCharts = [];
    if (selectedReviewDate === "Monthly" && selectedYear !== "All") {
      const months = Object.keys(
        dataToUse[tenantVars[tenantVars.indexOf(selectedSentiment)]]
          ?.review_sentiments || {}
      );
      const sortedMonths = sortMonths(months);
      pieCharts.push(...sortedMonths.map((month) => ({
        type: "month",
        data: dataToUse[selectedSentiment]?.review_sentiments[month]?.sentiment_values || {},
        label: month,
      })));
    } else if (selectedReviewDate === "Quarterly" && selectedYear !== "All") {
      const quarters = Object.keys(
        dataToUse[tenantVars[tenantVars.indexOf(selectedSentiment)]]
          ?.review_sentiments || {}
      );
      pieCharts.push(...quarters.map((quarter) => ({
        type: "quarter",
        data: dataToUse[selectedSentiment]?.review_sentiments[quarter]?.sentiment_values || {},
        label: quarter,
      })));
    } else if (selectedReviewDate === "Semi-Annually" && selectedYear !== "All") {
      const periods = Object.keys(
        dataToUse[tenantVars[tenantVars.indexOf(selectedSentiment)]]
          ?.review_sentiments || {}
      );
      pieCharts.push(...periods.map((period) => ({
        type: "period",
        data: dataToUse[selectedSentiment]?.review_sentiments[period]?.sentiment_values || {},
        label: period,
      })));
    } else if (selectedReviewDate === "Annually" && selectedYear !== "All") {
      if (selectedSentiment === "All") {
        pieCharts.push(...tenantVars.map((sentiment) => ({
          type: "sentiment",
          data: dataToUse[sentiment]?.review_sentiments[selectedYear]?.sentiment_values || {},
          label: sentiment,
        })));
      } else {
        pieCharts.push({
          type: "sentiment",
          data: dataToUse[selectedSentiment]?.review_sentiments[selectedYear]?.sentiment_values || {},
          label: selectedSentiment,
        });
      }
    } else if (selectedSentiment === "All") {
      pieCharts.push(...tenantVars.map((sentiment) => ({
        type: "sentiment",
        data: dataToUse[sentiment]?.review_sentiments?.All?.sentiment_values || {},
        label: sentiment,
      })));
    } else {
      pieCharts.push({
        type: "sentiment",
        data: dataToUse[selectedSentiment]?.review_sentiments?.All?.sentiment_values || {},
        label: selectedSentiment,
      });
    }
  
    // Add pie charts and tables to the PDF
    for (let i = 0; i < pieCharts.length; i += 4) {
      if (i > 0) {
        pdf.addPage(); // Add a new page for every 4 pie charts
        yPosition = 20; // Reset yPosition for the new page
      }
  
      const pieChartGroup = pieCharts.slice(i, i + 4);
      for (const pieChart of pieChartGroup) {
        const { data, label } = pieChart;
        const tableHTML = generateTableHTML(data, label);
  
        // Create a temporary container for the table
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = tableHTML;
        document.body.appendChild(tempDiv);
  
        // Capture the table as an image
        const tableCanvas = await html2canvas(tempDiv);
        const tableImgData = tableCanvas.toDataURL("image/png");
        const tableImgWidth = 280; // Adjust based on your layout
        const tableImgHeight = (tableCanvas.height * tableImgWidth) / tableCanvas.width;
  
        // Check if the table exceeds the remaining space on the current page
        if (yPosition + tableImgHeight > pdf.internal.pageSize.height - 20) {
          // Add a new page if the table exceeds the remaining space
          pdf.addPage();
          yPosition = 20; // Reset yPosition for the new page
        }
  
        // Add the table image to the PDF
        pdf.addImage(tableImgData, "PNG", 10, yPosition, tableImgWidth, tableImgHeight);
        yPosition += tableImgHeight + 10; // Update yPosition for the next table
  
        // Clean up the temporary container
        document.body.removeChild(tempDiv);
      }
    }
  
    // Save the PDF
    pdf.save("sentiment_pie_charts_with_tables.pdf");
  };

  const renderPieCharts = () => {
    let dataToUse;

    if (apiResponse) {
      const jsonObjects = apiResponse.split("\n");
      jsonObjects.forEach((json) => {
        const trimmedJson = json.trim(); // Remove extra spaces and newlines
        if (trimmedJson) {
          const jsonString = trimmedJson.startsWith("data:")
            ? trimmedJson.slice(5)
            : trimmedJson;
          const jsonData = JSON.parse(jsonString);
          if (jsonData.success) {
            dataToUse = jsonData.data.sentiments;
          }
        }
      });
    }
    // Prioritize API response data only if it is successful
    if (!dataToUse) {
      dataToUse = sentiments;
    }
    try {
      if (selectedReviewDate === "Annually" && selectedYear !== "All") {
        if (selectedSentiment === "All") {
          return (
            <div style={useStyles.gridContainer}>
              {tenantVars.map((sentiment) => {
                const sentimentData =
                  dataToUse[sentiment]?.review_sentiments[selectedYear]
                    ?.sentiment_values || {};
                const chartData = getChartData(sentimentData);
                return (
                  <div key={sentiment}>
                    <h3>{sentiment}</h3>
                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      type="pie"
                      height={350}
                    />
                  </div>
                );
              })}
            </div>
          );
        } else {
          const sentimentData =
            dataToUse[selectedSentiment]?.review_sentiments[selectedYear]
              ?.sentiment_values || {};
          const chartData = getChartData(sentimentData);
          return (
            <div style={useStyles.singleChartContainer}>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="pie"
                height={450}
              />
            </div>
          );
        }
      } else if (
        selectedReviewDate === "Semi-Annually" &&
        selectedYear !== "All"
      ) {
        const periods = Object.keys(
          dataToUse[tenantVars[tenantVars.indexOf(selectedSentiment)]]
            ?.review_sentiments || {}
        );
        return (
          <div style={useStyles.gridContainer}>
            {periods.map((period) => {
              const periodData =
                dataToUse[selectedSentiment]?.review_sentiments[period]
                  ?.sentiment_values || {};
              const chartData = getChartData(periodData);
              return (
                <div key={period}>
                  <h3>{period}</h3>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                    height={350}
                  />
                </div>
              );
            })}
          </div>
        );
      } else if (selectedReviewDate === "Quarterly" && selectedYear !== "All") {
        const quarters = Object.keys(
          dataToUse[tenantVars[tenantVars.indexOf(selectedSentiment)]]
            ?.review_sentiments || {}
        );
        return (
          <div style={useStyles.gridContainer}>
            {quarters.map((quarter) => {
              const quarterData =
                dataToUse[selectedSentiment]?.review_sentiments[quarter]
                  ?.sentiment_values || {};
              const chartData = getChartData(quarterData);
              return (
                <div key={quarter}>
                  <h3>{quarter}</h3>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                    height={350}
                  />
                </div>
              );
            })}
          </div>
        );
      } else if (selectedReviewDate === "Monthly" && selectedYear !== "All") {
        const months = Object.keys(
          dataToUse[tenantVars[tenantVars.indexOf(selectedSentiment)]]
            ?.review_sentiments || {}
        );
      
        // Sort the months in the correct order
        const sortedMonths = sortMonths(months);
      
        return (
          <div style={useStyles.gridContainer}>
            {sortedMonths.map((month) => {
              const monthData =
                dataToUse[selectedSentiment]?.review_sentiments[month]
                  ?.sentiment_values || {};
              const chartData = getChartData(monthData);
              return (
                <div key={month}>
                  <h3>{month}</h3>
                  <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                    height={350}
                  />
                </div>
              );
            })}
          </div>
        );
      } else {
        if (selectedSentiment === "All") {
          return (
            <div style={useStyles.gridContainer}>
              {tenantVars.map((sentiment) => {
                const sentimentData =
                  dataToUse[sentiment]?.review_sentiments?.All
                    ?.sentiment_values || {};
                const chartData = getChartData(sentimentData);
                return (
                  <div key={sentiment}>
                    <h3>{sentiment}</h3>
                    <ReactApexChart
                      options={chartData.options}
                      series={chartData.series}
                      type="pie"
                      height={350}
                    />
                  </div>
                );
              })}
            </div>
          );
        } else {
          const allData =
            dataToUse[selectedSentiment]?.review_sentiments?.All
              ?.sentiment_values || {};
          const chartData = getChartData(allData);
          return (
            <div style={useStyles.singleChartContainer}>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="pie"
                height={450}
              />
            </div>
          );
        }
      }
    } catch (err) {
      setError("Failed. Please try again.");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "20px",
        borderRadius: "10px",
        position: "relative",
      }}
    >
      {/* Download Button */}
      <IconButton
        onClick={handleDownloadPdf}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          zIndex: 1,
        }}
      >
        <Download />
      </IconButton>

      <div style={{ display: "flex" }}>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          style={{ width: "200px", marginRight: "20px" }}
        >
          <MenuItem value="All">All</MenuItem>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedReviewDate}
          onChange={handleReviewDateChange}
          disabled={selectedYear === "All"}
          style={{ width: "200px", marginRight: "20px" }}
        >
          <MenuItem
            value={selectedYear === "All" ? "All" : "Choose Review Date"}
          >
            {selectedYear === "All" ? "All" : "Choose Review Date"}
          </MenuItem>
          <MenuItem value="Monthly">Monthly</MenuItem>
          <MenuItem value="Quarterly">Quarterly</MenuItem>
          <MenuItem value="Semi-Annually">Semi-Annually</MenuItem>
          <MenuItem value="Annually">Annually</MenuItem>
        </Select>
        <Select
          value={selectedSentiment}
          onChange={handleSentimentChange}
          style={{ width: "200px", marginRight: "20px" }}
        >
          <MenuItem
            value={selectedYear === "All" ? "All" : "Choose a Sentiment"}
          >
            {selectedYear === "All" ? "All" : "Choose a Sentiment"}
          </MenuItem>
          {selectedReviewDate === "Annually" && (
            <MenuItem value="All">All</MenuItem>
          )}
          {tenantVars.map((sentiment) => (
            <MenuItem key={sentiment} value={sentiment}>
              {sentiment}
            </MenuItem>
          ))}
        </Select>
        <Button
          onClick={sendApiRequest}
          disabled={isButtonDisabled}
          style={{
            padding: "10px 20px",
            backgroundColor: isButtonDisabled ? "#ccc" : "#4caf50",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: isButtonDisabled ? "not-allowed" : "pointer",
          }}
        >
          Fetch Data
        </Button>
      </div>
      {validationError && (
        <Alert severity="error" style={{ marginTop: "10px" }}>
          {validationError}
        </Alert>
      )}
      {loading ? (
        isDataFromAPI ? (
          <div>
            <div style={{ marginTop: "10px", width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": { backgroundColor: "#4caf50" },
                }}
              />
              <p style={{ textAlign: "center", marginTop: "5px" }}>
                Processing: {progress.toFixed(2)}%
              </p>
            </div>
          </div>
        ) : (
          <LoadingSpinner />
        )
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <div ref={chartRef}>{renderPieCharts()}</div>
      )}
    </div>
  );
};

export default SentimentPieChart;