import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import FetchLoading from '../Components/Messages/FetchLoading';

export default function DataGridTable({ columns, data, title, options, loading }) {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h4>{title}</h4>
        {options}
      </div>
      {loading ? (
        <div style={{ height: 350, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <FetchLoading />
        </div>
      ) : (
        <DataGrid
          rows={data}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          initialState={{
            pagination: {
              paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        />
      )}
    </div>
  );
}