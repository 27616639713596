import MainSideBar from "../../../Components/SideBar";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetBranchesQuery, usePostBranchComparisonMutation } from "../../../Services/ApiServices";
import { Button, FormControlLabel, Checkbox, FormGroup, Box, Grid, Typography } from "@mui/material";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import { useNavigate } from "react-router-dom";
import {
  LinearProgress,
} from "@mui/material";

export default function CompareBranches() {
  const places = useSelector(state=> state.place)
  
  
  const { data, error } = useGetBranchesQuery();
  const [branchList, setBranchList] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]); // Track selected branches
  const [err, setErr] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  const [postBranchComparison, { isLoading: isComparing, error: compareError }] = usePostBranchComparisonMutation();

  useEffect(() => {
    if (places) {
      setBranchList(places);
      setLoading(false);
    } else if (error) {
      
      setErr({ error: true, message: "Failed to load branches" });
      setLoading(false);
    }
  }, [places]);

  // Handle checkbox toggle
  const handleBranchToggle = (branchId) => {
    setSelectedBranches((prevSelected) => {
      if (prevSelected.includes(branchId)) {
        return prevSelected.filter((id) => id !== branchId);
      } else {
        return [...prevSelected, branchId];
      }
    });
  };
  const getToken = () => sessionStorage.getItem("token") || "";


 const handleCompare = async () => {
  if (selectedBranches.length > 1) {
    setLoading(true);
    try {
      const url = `http://161.35.229.180/api/comparisons/branches`;
      const headers = {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: getToken(),
      };
      const body = {
        branchIds: selectedBranches,
      };

      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        // Decode the streamed data and append to the buffer
        buffer += decoder.decode(value, { stream: true });

        // Process complete JSON objects in the buffer
        let boundary;
        while ((boundary = buffer.indexOf("\n")) !== -1) {
          // Extract the first complete JSON object
          const jsonString = buffer.slice(0, boundary).trim();
          buffer = buffer.slice(boundary + 1); // Remove the processed part from the buffer

          if (jsonString) {
            try {
              // Remove the "data: " prefix if it exists
              const cleanedJsonString = jsonString.startsWith("data: ")
                ? jsonString.slice(6) // Remove "data: "
                : jsonString;

              const data = JSON.parse(cleanedJsonString);


              if (data.progress !== undefined) {
                setProgress(data.progress); // Update progress
              } else if (data.success) {
                // Handle the final data (e.g., set it to state or process it further)
        navigate("/ViewBranchComparison", { state: { comparisonData: data.data } });

              }
              else{
                
                setErr({error:true,message:data.data.message})
              }
            } catch (error) {
              console.error("Error parsing JSON:", error);
              console.error("Problematic JSON string:", jsonString);
            }
          }
        }
      }

      // Handle any remaining data in the buffer
      if (buffer.trim()) {
        try {
          // Remove the "data: " prefix if it exists
          const cleanedJsonString = buffer.startsWith("data: ")
            ? buffer.slice(6) // Remove "data: "
            : buffer;

          const data = JSON.parse(cleanedJsonString);
        navigate("/ViewBranchComparison", { state: { comparisonData: data.data } });


          // Handle the final data
        } catch (error) {
          console.error("Error parsing final JSON:", error);
          console.error("Problematic JSON string:", buffer);
        }
      }

      setLoading(false);
    } catch (error) {
      if (
        error.response?.status === 400 ||
        error.response?.status === 440
      ) {
        sessionStorage.removeItem("token"); // Clear token
        navigate("/login"); // Redirect to login page
      } else {
        setErr({ error: true, message: error.message });
      }
   
    }
  } else {
    setErr({ error: true, message: "Please select at least two branches" });
  }
};
  

  return (
    <MainSideBar>
      <Box sx={{ padding: "2rem", maxWidth: "1200px", margin: "auto" }}>
        <Typography variant="h4" gutterBottom>
          Compare Branches
        </Typography>
        {loading ? (
          <div>
            <div style={{ marginTop: "10px", width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#e0e0e0",
                  "& .MuiLinearProgress-bar": { backgroundColor: "#4caf50" },
                }}
              />
              <p style={{ textAlign: "center", marginTop: "5px" }}>
                Processing: {progress.toFixed(2)}%
              </p>
            </div>
          </div> 
        ) : err.error ? (
          <Typography color="error">Error: {err.message}</Typography>
        ) : (
          <>
            <Box sx={{ marginBottom: "2rem" ,backgroundColor: "white", padding:"2rem" }}>
              <Typography variant="h6" gutterBottom>
                Select Branches to Compare
              </Typography>

              <Grid container spacing={3}>
                {branchList.map((branch) => (
                  <Grid item xs={12} sm={6} md={4} key={branch.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedBranches.includes(branch.branchId)}
                          onChange={() => handleBranchToggle(branch.branchId)}
                          color="primary"
                        />
                      }
                      label={<Typography variant="subtitle1" fontWeight="bold">{branch.displayName}</Typography>}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleCompare}
              disabled={selectedBranches.length < 2 || isComparing}
              sx={{ padding: "0.75rem 2rem", fontSize: "1rem", marginTop: "1rem" }}
            >
              {loading ? "comparing ...": "Compare Branches"}
            </Button>

            {compareError && (
              <Typography color="error" sx={{ marginTop: "1rem" }}>
                Error: Failed to compare branches.
              </Typography>
            )}

            {err.error && (
              <Typography color="error" sx={{ marginTop: "1rem" }}>
                {err.message}
              </Typography>
            )}
          </>
        )}
      </Box>
    </MainSideBar>
  );
}
