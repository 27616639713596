import Gallery from "./ImageGallery";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Rating,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";

export default function Displayer({hotel}) {
    
    const theme = useTheme();
    

  return (
    <Paper sx={{ width: "100%", padding: "1%", height: "auto" }}>
      <Button
        variant="outlined"
        sx={{margin:"12px 0"}}
        onClick={() => {
          window.history.go(-1);
          return false;
        }}
      >
        Go Back
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} lg={12} md={12}>
          <Card
            sx={{ backgroundColor: theme.palette.primary.main, padding: "1%" }}
          >
            <Typography variant="h6" color="white">
              Place Name : {hotel.displayName}
            </Typography>
            <Typography variant="h6" color="white">
              Place Address : {hotel.formattedAddress}
            </Typography>
            <Typography variant="h6" color="white">
              Rating : {hotel.rating}
            </Typography>
            <Rating
              name="read-only"
              value={hotel.rating}
              precision={0.25}
              readOnly
            />
          </Card>
        </Grid>

        <Grid item xs={12} xl={12} lg={12} md={12}>
          <Box
            sx={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Gallery />
          </Box>
        </Grid>
        <Grid item xs={12} xl={12} lg={12} md={12}>
          <Typography variant="h6" color="primary">
            Editorial Summary
          </Typography>
          <Divider />
          <Typography variant="body1">{hotel.editorialSummary}</Typography>
        </Grid>
        <Grid item xs={12} xl={12} lg={12} md={12}></Grid>
      </Grid>
    </Paper>
  );
}
